// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-gbk-js": () => import("./../../../src/pages/Work/GBK.js" /* webpackChunkName: "component---src-pages-work-gbk-js" */),
  "component---src-pages-work-hello-lamp-post-js": () => import("./../../../src/pages/Work/HelloLampPost.js" /* webpackChunkName: "component---src-pages-work-hello-lamp-post-js" */),
  "component---src-pages-work-hsbc-js": () => import("./../../../src/pages/Work/HSBC.js" /* webpackChunkName: "component---src-pages-work-hsbc-js" */),
  "component---src-pages-work-hyper-jar-js": () => import("./../../../src/pages/Work/HyperJar.js" /* webpackChunkName: "component---src-pages-work-hyper-jar-js" */),
  "component---src-pages-work-okoach-js": () => import("./../../../src/pages/Work/Okoach.js" /* webpackChunkName: "component---src-pages-work-okoach-js" */),
  "component---src-pages-work-stamp-js": () => import("./../../../src/pages/Work/Stamp.js" /* webpackChunkName: "component---src-pages-work-stamp-js" */)
}

